// import orderTableData from "mockData/orderTableData";
import { reDispatch } from "../generic/actions";
// import { setClientTypeGlobal } from "./company";
import { 
    ADD_ITEMS,
    SET_ITEMS,
    CLEAR_ITEMS,
    SET_DV_SELECTED_FILTERS,
    RESET_DV,
    SET_DV_STRING,
    SET_DV_ITEMS,
    ADD_DV_ITEMS,
    TOGGLE_SELECT_ITEM,
    TOGGLE_SELECT_ALL_ITEMS,
    ADD_SAVED_FILTER,
    DELETE_SAVED_FILTER,
    ACTIVATE_SAVED_FILTER,
    SET_MORE_ACTION_ROW_ID,
    TOGGLE_SORT,
    RESET_ACTIONS,
    SET_DV_ID,
    SET_COLUMS,
} from "./constants"


import {
    setColumns,
    setDataViewId,
    setDataItems,
    addDataItems,
    addSavedFilter,
    toggleSelectedItem,
    toggleSort,
    setMoreOpenRowId,
    toggleSelectAllItems,
    deleteSavedFilter,
    activateSavedFilter,
    resetDV,
    setDVsearchString,
    setDVitems,
    addDVitems,
    setFilterSelection
} from "./actions";


const mockSavedFilters = [
  {
    id: "USF-0000aaaa",
    favorite: true,
    name: "My precious",
    data: {
      "FLT-SMaaaa00": ["2"],
    },
  },
  {
    id: "USF-0000aaab",
    favorite: false,
    name: "My filter",
    data: {
      "FLT-SMaaaa00": ["2"],
      "FLT-RGaaaa00": [0, 530],
    },
  },
];

const initialState = {
  viewId: '',
  items: [],
  selectedItems: [],
  searchString: "",
  columns: null,
  sort: [],
  moreOpenRowId: "",
  // allFilters: [],
  selectedFilters: {
    // 'FLT-RGaaaa00' : [200, 500],
    // "FLT-sort0prc" : ["both"],
    // "FLT-PCATEG00" : ["#1", "#45"],
    // "FLT-PCATEG01" : ["#1#4", "#45#46"],
  },
  lastItemHint: 0,
  savedFilters: [...mockSavedFilters],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_DV:
      return {
        initialState,
      };
      case CLEAR_ITEMS:
        return {
          ...state,
          items: [],
        };
      case SET_COLUMS:
        return {
          ...state,
          columns: action.payload,
        };
      case SET_DV_ID:
        return {
          ...initialState,
          viewId:action.payload,
        };
      case SET_ITEMS:
        return {
          ...state,
          items:action.payload.items,
          lastItemHint: action.payload.lastItemHint,
        };
      case ADD_ITEMS:
        return {
          ...state,
          items: [...(state.items??[]), ...action.payload.items],
          lastItemHint: action.payload.lastItemHint,
        };
    case SET_MORE_ACTION_ROW_ID:
      return {
        ...state,
        selectedItems: [], //closes more actions modal
        moreOpenRowId: action.payload === state.moreOpenRowId ? "" : action.payload,
      };
    case TOGGLE_SORT:
      return {
        ...state,
        sort: (state?.sort[0] === action.payload[0] && state?.sort[1] === action.payload[1])
          ? []
          : action.payload
      };
    case SET_DV_STRING:
      return {
        ...state,
        searchString: action.payload,
      };
    case TOGGLE_SELECT_ITEM:
      return {
        ...state,
        moreOpenRowId: '', //closes any open action
        selectedItems: state.selectedItems.includes(action.payload)
          ? state.selectedItems.filter(item => item != action.payload)
          : [...state.selectedItems, action.payload]
      };
    case RESET_ACTIONS:
      return {
        ...state,
        moreOpenRowId: '', //closes any open action
        selectedItems: [],
      };
    case TOGGLE_SELECT_ALL_ITEMS:
      return {
        ...state,
        moreOpenRowId: '', //closes any open action
        selectedItems: state.selectedItems.length === state.items.length
          ? []
          : state.items.map(item => item?.id)
      };
    case SET_DV_ITEMS:
      return {
        ...state,
        rows: action.payload.rows,
      };
    case ADD_DV_ITEMS:
      return {
        ...state,
        rows: [...state.rows, ...action.payload.rows],
        lastRowHint: action.payload.lastRowHint,
      };
    case ADD_SAVED_FILTER:
      return {
        ...state,
        savedFilters: [...state.savedFilters, action.payload],
      };
    case ACTIVATE_SAVED_FILTER:
      return {
        ...state,
        selectedFilters: state.savedFilters.find((e) => e.id === action.payload).data,
      };
    case DELETE_SAVED_FILTER:
      return {
        ...state,
        savedFilters: state?.savedFilters && state.savedFilters.filter((item) => item.id !== action.payload),
      };
    case SET_DV_SELECTED_FILTERS:
      // let prev = state.selectedFilters
      return {
        ...state,
        selectedFilters: action.payload?.id
          ? {
              ...state.selectedFilters,
              [action.payload.id]: action.payload.selection,
            }
          : undefined,
      };
    default:
      return state;
  }
}
