export const adminLeftMenu = [
    {
      name: 'Dashboard',
      //svg: 'homepage',
      svg: 'dashboard1',
      url: '/admin/dashboard'
    },
  
    {
      name: 'Utilizatori',
      svg: 'manageusers',
      url: '/admin/user/dashboard',
      children: [
        {
          name: 'Dashboard',
          svg: 'homepage',
          url: '/admin/user/dashboard'
        },
        {
          name: 'Conturi Clienti',
          svg: 'menulist',
          url: '/admin/user/client/list'
        },
        {
          name: 'Roles',
          svg: 'resetpass',
          url: '/admin/user/role/list'
        },
        {
          name: 'Angajati Uniprest',
          svg: 'unipreststaff',
          url: '/admin/user/uni/list'
        }
      ]
    },
  
    {
      name: 'Organizatii',
      svg: 'Business', //taken from design
      url: '/admin/company/dashboard', //???business
      children: [
        {
          name: 'Dashboard',
          svg: 'homepage',
          url: '/admin/company/dashboard'
        },
        {
          name: 'Toate Companiile',
          //svg: 'invoice',
          svg: 'list1',//
          url: '/admin/company/client/list'
        },
        {
          name: 'Adrese Livrare',
          svg: 'uniprestplus', //taken from design
          url: '/admin/company/accountrequests/list'
          //url: '/admin/company/delivery/list/' //???
        },
        {
          name: 'Cereri cont',
          svg: 'delivery',
          //url: '/admin/company/accountrequests/list'
          url: '/admin/company/delivery/list/'
        }
      ]
    },
    {
      name: 'Produse',
      svg: 'product',
      // icon: ManageUsers,
      url: '/admin/product/dashboard',
      children: [
        {
          name: 'Dashboard',
          svg: 'homepage',
          url: '/admin/product/dashboard'
        },
        {
          name: 'B2B Items',
          //svg: 'invoice',
          svg: 'uniprestglobalproducts',
          url: '/admin/product/b2b/list'
        },
        {
          name: 'Compano',
          svg: 'compano-products',
          url: '/admin/product/compano/list'
        },
        {
          name: 'Grila de discounturi',
          svg: 'grila-discount',
          url: '/admin/product/discount/list',
          roForm: true,
        },
        {
          name: 'Net nete',
          svg: 'nete-nete',
          url: '/admin/product/net/list',
          roForm: true,
        },
        {
          name: 'Discount Volum',
          svg: 'volume-discount',
          url: '/admin/product/volume/list'
        },
        {
          name: 'Pachete',
          svg: 'discount-pachete',
          url: '/admin/product/pack/list'
        },
        {
          name: 'Matrice Produse',
          svg: 'kanban',
          url: '/admin/product/matrix/list'
        },
        {
          name: 'Badges',
          svg: 'badge',
          url: '/admin/product/badge/list'
        },
        {
          name: 'Intrebari si raspunsuri',
          svg: 'airecommendations',
          url: '/admin/product/qa/list'
        },
  
        {
          name: 'Recenzii',
          svg: 'reviewstar',
          url: '/admin/product/review/list'
        },
        // {
        //   name: 'Star',
        //   svg: 'review-star',
        //   url: '/admin/product/review/list'
        // },
        // {
        //   name: 'WareHouse',
        //   svg: 'warehouse',
        //   url: '/admin/product/review/list'
        // },
        {
          name: 'Documente',
          svg: 'product-document',
          url: '/admin/product/document/list'
        },
        {
          name: 'Lista Depozite',
          svg: 'Warehouse', //taken from design
          url: '/admin/warehouse/list' //???
        }
      ]
    },
  
    {
      name: 'Comenzi',
      svg: 'invoicedollar', //taken from design
      url: '/admin/order/dashboard', //???
      children: [
        {
          name: 'Dashboard',
          svg: 'homepage', //taken from design
          url: '/admin/order/dashboard' //???
        },
        {
          name: 'Lista Comenzi',
          svg: 'list1', //taken from design
          url: '/admin/order/list' //???
        },
        {
          name: 'Lista Facturi',
          svg: 'invoice', //taken from design
          url: '/admin/order/invoice/list' //???
        }
      ]
    },
  
    {
      name: 'Liste predefinite',
      svg: 'listmodels', //taken from design
      url: '/admin/admin-lists/dashboard', //???
      children: [
        {
          name: 'Dashboard',
          svg: 'homepage', //taken from design
          url: '/admin/admin-lists/dashboard' //???
        },
        {
          name: 'Lista Liste PRedefinite',
          svg: 'list1', //taken from design
          url: '/admin/admin-lists/list' //???
        }
      ]
    },
  
    {
      name: 'Guest',
      svg: 'webpage', //taken from design
      url: '/admin/guest/dashboard',
      children: [
        {
          name: 'Dashboard',
          svg: 'homepage', //taken from design
          url: '/admin/guest/dashboard' //???
        },
        {
          name: 'Stiri',
          svg: 'Documents3', //taken from design
          url: '/admin/guest/news/list' //???
        },
        {
          name: 'Anunturi angajari',
          svg: 'Jobs2', //taken from design
          url: '/admin/guest/job/list' //???
        },
        {
          name: 'Aplicanti joburi',
          svg: 'jobs1', //taken from design
          url: '/admin/guest/candidate/list' //???
        },
        {
          name: 'Cereri Contact',
          //svg: 'email', //taken from design
          svg: 'sendemail',
          url: '/admin/guest/contact/list' //???
        },
        {
          name: 'Locatii Uniprest',
          svg: 'maps', //taken from design
          url: '/admin/guest/location/list' //???
        }
      ]
    },
  
    // {
    //   name: 'Depozite',
    //   svg: 'Warehouse', //taken from design
    //   url: '/admin/warehouse/list', //???
    //   children: [
    //     {
    //       name: 'Lista Depozite',
    //       svg: 'Warehouse', //taken from design
    //       url: '/admin/warehouse/list' //???
    //     }
    //   ]
    // }
  
    // {
    //   name: "Cereri de oferta",
    //   subMenu: true,
    //   svg: "RFPList", //taken from design
    //   url: "/admin/offer/list/", //??? RFPList
    // },
    // {
    //   name: "Proiecte",    subMenu: true,
    //   svg: "Projects", //taken from design
    //   url: "/admin/project/list/", //??? RFPList
    // },
    // {
    //   name: "Stiri",
    //   svg: "Documents3", //taken from design
    //   url: "/admin/news/list/",
    //   subMenu: true,
    //   rightTopButtons: [
    //     {
    //       name: "Dashboard",
    //       svg: "homepage",
    //       url: "/admin/news/",
    //     },
    //     {
    //       name: "All Items",
    //       svg: "invoice",
    //       url: "/admin/news/list",
    //     },
    //   ]
    // },
    // {
    //   name: "Joburi",
    //   subMenu: true,
  
    //   svg: "Jobs2", //taken from design
    //   url: "list", //???
    // },
  
    // {
    //   name: "Pagini de prezentare",
    //   svg: "WebPage", //taken from design
    //   url: "/admin/pages/", //???
    // },
    // {
    //   name: "Promotii",
    //   svg: "Promo", //taken from design
    //   url: "/admin/promo/", //???
    // },
  ]
  
  export const adminBottomMenu = [
    // {
    //   name: "The other thing",
    //   svg: "stats",
    //   url: "/admin/settings",
    // },
    // {
    //   name: 'Settings',
    //   svg: 'settings',
    //   url: '/admin/settings'
    // }
  ]
  