export const SET_COLLAPSED_MENU = "page/SET_COLLAPSED_MENU";
export const SET_CRT_LIST_ITEMS = "page/SET_CRT_LIST_ITEMS";
export const ADD_CRT_LIST_ITEMS = "page/ADD_CRT_LIST_ITEMS";
export const SET_LEFT_MENU_ITEMS = "page/SET_LEFT_MENU_ITEMS";
export const SET_PAGE_MODAL_DATA = "page/SET_PAGE_MODAL_DATA";
export const SET_PAGE_MODAL = "page/SET_PAGE_MODAL";
export const SET_PAGE_EDIT = "page/SET_PAGE_EDIT";
export const SET_WIZZARD_STEP = "page/SET_WIZZARD_STEP";
export const RESET_CRT_LIST = "page/RESET_CRT_LIST";
export const SET_CRT_LIST_SEARCH_STRING = "page/SET_CRT_LIST_SEARCH_STRING";
export const SET_CRT_FORM_ITEM = "page/SET_CRT_FORM_ITEM";
export const SET_SCROLL_POSITION = "page/SET_SCROLL_POSITION";
export const SET_ACTION_TO_CONFIRM = "page/SET_ACTION_TO_CONFIRM";
export const SET_TEMP_PRODUCT_FIX = "page/SET_TEMP_PRODUCT_FIX";
export const SET_LOADING_INDICATOR = "page/SET_LOADING_INDICATOR";
export const SET_ALL_FILED_ERRORS = "page/SET_ALL_FILED_ERRORS";