/* eslint-disable import/no-anonymous-default-export */
// import store from "store";

// import store from "store";
import { adminLeftMenu } from "../../data/adminMenu";
import {
  SET_COLLAPSED_MENU,
  SET_CRT_LIST_ITEMS,
  ADD_CRT_LIST_ITEMS,
  SET_LEFT_MENU_ITEMS,
  SET_PAGE_MODAL_DATA,
  SET_PAGE_MODAL,
  SET_PAGE_EDIT,
  SET_WIZZARD_STEP,
  RESET_CRT_LIST,
  SET_CRT_LIST_SEARCH_STRING,
  SET_CRT_FORM_ITEM,
  SET_SCROLL_POSITION,
  SET_ACTION_TO_CONFIRM,
  SET_TEMP_PRODUCT_FIX,
  SET_LOADING_INDICATOR,
  SET_ALL_FILED_ERRORS,
} from "./constants";

import {
  setTempProductFix,
  setActionToConfirm,
  setPageModal,
  setPageModalData,
  setLeftMenuItems,
  setWizzardStep,
  resetCrtList,
  setCrtList,
  setCrtListSearchString,
  setCrtFormItem,
  setPageEdit,
  setCollapsedMenu,
  setScrollPostion,
} from "./actions";

const initialcrtList = {
  items: [],
  lastItemHint: null,
  searchString: "",
}
const initialState = {
  collapsedMenu: false,
  isEdit: false,
  crtFormItem: undefined,
  actionToConfirm: undefined,
  modal: undefined,
  modalData: undefined,
  crtList: initialcrtList,
  wizzardStep: 1,
  leftMenuItems: adminLeftMenu,
  tempProductFix: {},
  fieldErrors: {},
  loadingIndicators: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_INDICATOR:
      return {
        ...state,
        loadingIndicators: { ...state.loadingIndicators, ...action.payload },
      };
    case SET_ALL_FILED_ERRORS:
      return {
        ...state,
        fieldErrors: action.payload,
      };
    case SET_TEMP_PRODUCT_FIX:
      return {
        ...state,
        tempProductFix: action.payload,
      };
    case SET_WIZZARD_STEP:
      return {
        ...state,
        wizzardStep: action.payload,
      };
    case SET_ACTION_TO_CONFIRM:
      return {
        ...state,
        actionToConfirm: action.payload,
        modal: action.payload ? "SYS-confirm-action" : undefined
      };
    case SET_CRT_FORM_ITEM:
      return {
        ...state,
        crtFormItem: action.payload.combine
          ? { ...state.crtFormItem, ...action.payload.data }
          : action.payload.data,
      };
    case SET_PAGE_MODAL:
      return {
        ...state,
        modal: action.payload?.name,
        modalData: action.payload?.data ?? state.modalData,
      };
    case SET_PAGE_MODAL_DATA:
      return {
        ...state,
        modalData: action.payload,
      };
    case SET_PAGE_EDIT:
      return {
        ...state,
        isEdit: action.payload ?? false,
      };
    case SET_LEFT_MENU_ITEMS:
      return {
        ...state,
        leftMenuItems: action.payload,
      };
    case SET_CRT_LIST_SEARCH_STRING:
      return {
        ...state,
        crtList: {
          ...state.crtList,
          searchString: action.payload,
        }
      };
    case SET_CRT_LIST_ITEMS:
      return {
        ...state,
        crtList: {
          ...state.crtList,
          items: action.payload.items,
          lastItemHint: action.payload.lastItemHint,
        }
      };
    case ADD_CRT_LIST_ITEMS:
      return {
        ...state,
        crtList: {
          ...state.crtList,
          items: [...state.crtList.items, ...action.payload.items],
          lastItemHint: action.payload.lastItemHint,
        }
      };
    case RESET_CRT_LIST:
      return {
        ...state,
        crtList: initialcrtList,
      };
    case SET_COLLAPSED_MENU:
      return {
        ...state,
        collapsedMenu: action.payload,
      };
    case SET_SCROLL_POSITION:
      return {
        ...state,
        setScrollPosition: action.payload,
      };
    default:
      return state;
  }
}